import { patchState, signalStoreFeature, withMethods, withState } from "@ngrx/signals";

type AiChatState = {
    ai_status: AiChatStatus | '';
}

export interface AiChatEvent {
    [key: string]: any;
    ai_state?: AiChatStatus;
}

export enum AiChatStatus {
    AI_STATE_THINKING = 'AI_STATE_THINKING',
    AI_STATE_GENERATING = 'AI_STATE_GENERATING'
}

export function withAiChatStatus<_>() {
    return signalStoreFeature(
        withState<AiChatState>({ ai_status: '' }),
        withMethods((store) => {
            function setAiStatus(event: AiChatEvent) {
                patchState(store, { ai_status: event.ai_state || '' });
            }
            return {setAiStatus};
        })
    )
}