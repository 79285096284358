import { AsyncPipe, CommonModule, JsonPipe, NgClass } from '@angular/common';
import { AfterViewInit, Component, computed, EventEmitter, inject, Output, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LucideAngularModule } from 'lucide-angular';
import { ChatStore, ChatView } from 'src/app/stores/chat/chat.store';
import { UserStore } from 'src/app/stores/user.store';
import { CustomTemplatesService, StreamAutocompleteTextareaModule, StreamChatModule, StreamI18nService, ThemeService } from 'stream-chat-angular';
import { ChatHeaderComponent } from "../chat-header/chat-header.component";
import { ChatChannelSearchComponent } from '../chat-channel-search/chat-channel-search.component';
import { OndemandUtilsService } from 'src/app/services/ondemand.utils/ondemand.utils.service';
import { AiStatusComponent } from "../ai-status/ai-status.component";

@Component({
  selector: 'app-chat-window',
  standalone: true,
  imports: [StreamChatModule, TranslateModule, StreamAutocompleteTextareaModule, LucideAngularModule, NgClass, ChatHeaderComponent, ChatChannelSearchComponent, JsonPipe, AsyncPipe, CommonModule, AiStatusComponent],
  templateUrl: './chat-window.component.html',
  styleUrl: './chat-window.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class ChatWindowComponent implements AfterViewInit {
  // Services
  chatStore = inject(ChatStore);
  userStore = inject(UserStore);
  ondemandUtils = inject(OndemandUtilsService);
  themeService = inject(ThemeService);
  customTemplatesService = inject(CustomTemplatesService);
  streamI18nService = inject(StreamI18nService);
  chatAvailability = computed(() => this.ondemandUtils.getChatAvailabilityStatus(this.chatStore.availability(), this.chatStore.incompleteChats()));
  createChatDisabled = computed(() => this.chatStore.incompleteChats().length > 0 || this.chatStore.loadingChats() || this.chatStore.creatingChat() || this.chatAvailability() === 'unavailable');
  chatViewEnum = ChatView;
  // attachmentService = inject(this.customTemplatesService);
  // @ViewChild("customAttachmentPreviews")
  // customAttachmentPreviewsTemplate!: TemplateRef<CustomAttachmentListContext>;

  @Output() close = new EventEmitter<void>();

  /**
   * Constructor
   * Initializes Stream I18n Service and sets the theme to light.
   */
  constructor() {
    this.streamI18nService.setTranslation();
    this.themeService.theme$.next('light');
  }
  ngAfterViewInit(): void {
    // this.customTemplatesService.customAttachmentListTemplate$.next(this.customAttachmentPreviewsTemplate,)

  }
  /**
   * Creates a new chat with the active pet.
   */
  createNewChat() {
    const params = {
      pet_doc_id: this.userStore.activePet()?.doc_id || '',
      timezone: this.getClientTimezone(),
    }
    this.chatStore.startNewChat(params);
  }
  /**
   * @returns The client's timezone.
   */
  getClientTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
}
