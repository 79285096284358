import { Component, inject, computed } from '@angular/core';
import { ChatStore } from 'src/app/stores/chat/chat.store';
import { AiChatStatus } from 'src/app/stores/features/ai-chat-status/ai-chat-status.feature';

@Component({
  selector: 'app-ai-status',
  standalone: true,
  imports: [],
  templateUrl: './ai-status.component.html',
  styleUrl: './ai-status.component.scss'
})
export class AiStatusComponent {
  chatStore = inject(ChatStore);
  status = computed(() => this.getAIStatusText());
  AiChatStatus = AiChatStatus;

  getAIStatusText() {
    switch (this.chatStore.ai_status()) {
      case AiChatStatus.AI_STATE_THINKING:
        return 'AI Thinking...';
      case AiChatStatus.AI_STATE_GENERATING:
        return 'AI Typing...';
      default:
        return ''
    }
  }
}
