import { Component, OnInit, Renderer2, computed, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { JoiiHeader, JoiiHeaderEnvironments, JoiiHeaderItemState, JoiiHeaderItemType } from 'src/shared/components/design/joii-header/joii-header.interface';
import { AccountDataService } from './services/account.data.service/account.data.service';
import { BrazeService } from './services/braze.service/braze.service';
import { AuthenticationService } from 'src/shared/services/auth/auth_service';
import { HideFooterPath } from 'src/shared/components/design/joii-footer/joii-footer.interface';
import { environment } from 'src/environments/environment';
import { Analytics, initializeAnalytics, logEvent, setAnalyticsCollectionEnabled, setConsent, setDefaultEventParameters, setUserId } from '@angular/fire/analytics';
import { RemoteConfigService } from 'src/shared/services/remote-config/remote-config.service';
import { every, isArray, isBoolean, isString } from 'lodash';
import { UserStore } from './stores/user.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  remoteConfigService = inject(RemoteConfigService);
  userStore = inject(UserStore);
  hiddenSymptomCheckerTenantIds = computed(() => this.remoteConfigTenantIdsWithoutSymptomChecker());

  // Scroll to top on page transition
  constructor(
    private router: Router,
    private brazeService: BrazeService,
    private auth: AuthenticationService,
    private analytics: Analytics
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        logEvent(this.analytics, "page_view", { page_path: event.urlAfterRedirects, page_title: event.urlAfterRedirects })
      }
    });
    setAnalyticsCollectionEnabled(this.analytics, true)

    if (auth.isLoggedIn()) {
      brazeService.openCurrentUserSession();
      setUserId(analytics, auth.getUID())
    }
  }
  ngOnInit(): void {
    if (environment.production) {
      this.loadHotjar();
    }
  }

  private loadHotjar() {
    try {
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.innerHTML = ` (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4959314,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
      // Add to head
      document.head.appendChild(s);
    } catch (error) {
      console.error('Failed to load Hotjar');
    }
  }

  menu: JoiiHeader = {
    defaultHeaderPaths: [
      {
        path: '/',
        progress: 0
      },
      {
        path: '/login',
        progress: 0
      },
      {
        path: '/register',
        progress: 0
      },
      {
        path: '/forgottenpassword',
        progress: 0
      },
      {
        path: '/notfound',
        progress: 0
      },
      {
        path: '/tenants/*',
        progress: 0
      },
      {
        path: '/waitingroom/*',
        progress: 0
      },
      {
        path: '/consultation/*',
        progress: 0
      },
      {
        path: '/onboarding/*',
        progress: 0
      },
    ],
    logo: 'https://firebasestorage.googleapis.com/v0/b/vetai-e3e47.appspot.com/o/joii%2FJoii_logo.svg?alt=media&token=9046e258-a5ca-49a3-a22c-5fb78e920d15',
    items: [
      {
        index: 0,
        title: 'Vet Care',
        id: '/vet-care',
        type: JoiiHeaderItemType.Path,
        state: JoiiHeaderItemState.Both,
        environments: JoiiHeaderEnvironments.All
      },
      {
        index: 1,
        id: '/symptomchecker',
        title: 'Symptom Checker',
        type: JoiiHeaderItemType.Path,
        state: JoiiHeaderItemState.Both,
        environments: JoiiHeaderEnvironments.All,
        hidden: computed(() => this.isSymptomCheckerHidden())
      },
      {
        index: 2,
        id: '/account',
        title: 'My Account',
        type: JoiiHeaderItemType.Path,
        state: JoiiHeaderItemState.DesktopOnly,
        environments: JoiiHeaderEnvironments.All
      },
      {
        index: 3,
        id: '/account',
        title: 'My Account',
        type: JoiiHeaderItemType.Dropdown,
        state: JoiiHeaderItemState.MobileOnly,
        environments: JoiiHeaderEnvironments.All,
        subItems: [
          {
            index: 3.1,
            id: '/account/user',
            title: 'My Profile',
            type: JoiiHeaderItemType.Path,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
          {
            index: 3.2,
            id: '/account/pets',
            title: 'My Pets',
            type: JoiiHeaderItemType.Path,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
          {
            index: 3.3,
            id: '/address',
            title: 'My Address',
            type: JoiiHeaderItemType.Path,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
          {
            index: 3.4,
            id: '/account/appointments',
            title: 'My Appointments',
            type: JoiiHeaderItemType.Path,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
          {
            index: 3.5,
            id: '/account/resetpassword',
            title: 'Change Password',
            type: JoiiHeaderItemType.Path,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
          {
            index: 3.6,
            id: '/account/deleteaccount',
            title: 'Delete Account',
            type: JoiiHeaderItemType.Path,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          }
        ]
      },
      {
        index: 4,
        id: 'https://shop.joiipetcare.com/',
        title: 'Shop',
        type: JoiiHeaderItemType.Link,
        state: JoiiHeaderItemState.MobileOnly,
        environments: JoiiHeaderEnvironments.All
      },
      {
        index: 5,
        id: '/support',
        title: 'Support',
        type: JoiiHeaderItemType.Dropdown,
        state: JoiiHeaderItemState.MobileOnly,
        environments: JoiiHeaderEnvironments.All,
        subItems: [
          {
            index: 5.1,
            id: '/support/contact-us',
            title: 'Contact us',
            type: JoiiHeaderItemType.Path,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
          {
            index: 5.2,
            id: 'https://www.joiipetcare.com/privacy-policy/',
            title: 'Privacy policy',
            type: JoiiHeaderItemType.Link,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
          {
            index: 5.3,
            id: 'https://www.joiipetcare.com/terms-of-service/',
            title: 'Terms & Conditions',
            type: JoiiHeaderItemType.Link,
            state: JoiiHeaderItemState.MobileOnly,
            environments: JoiiHeaderEnvironments.All
          },
        ]
      },
      {
        index: 6,
        id: 'logout',
        title: 'Logout',
        type: JoiiHeaderItemType.Logout,
        state: JoiiHeaderItemState.Both,
        environments: JoiiHeaderEnvironments.All
      },
    ]
  }

  hideFooterPaths: HideFooterPath[] = [
    {
      path: '/login'
    },
    {
      path: '/register'
    },
    {
      path: '/forgottenpassword'
    },
    {
      path: '/tenants/*'
    },
    {
      path: '/onboarding/*'
    },
    {
      path: '/symptomchecker/session/*'
    },
    {
      path: '/recommendations*'
    }
  ];

  // uncomment to use callbacks
  // menuItemClicked(menuItem: JoiiHeaderItem) {

  // }

  /**
   * Parse tenant ids from remote config that should not show the symptom checker
   * @returns string[]
   */
  remoteConfigTenantIdsWithoutSymptomChecker() {
    const tenantIds = this.remoteConfigService.configurations().get('tenant_ids_without_symptom_checker');
    if (!isString(tenantIds)) return [];

    try {
      const parsedIds = JSON.parse(tenantIds);
      return (isArray(parsedIds) && every(parsedIds, isString)) ? parsedIds : [];
    } catch {
      return [];
    }
  }

  /**
   * Returns true if the symptom checker should be hidden
   * Hides symptom checker if the active tenant is in the list of tenant ids without the symptom checker.
   * Always shows symptom checker to staff users.
   * @returns boolean
   */
  isSymptomCheckerHidden() {
    const tenantIds = this.hiddenSymptomCheckerTenantIds();
    const loggedIn = this.auth.loggedIn();
    if (!loggedIn) return true;
    return tenantIds.includes(this.auth.getActiveTenant()?.id) && !this.auth.isStaff();
  }

}
