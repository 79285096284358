import { inject, Injectable, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { RemoteConfig, fetchAndActivate, getBoolean, getString, getValue } from '@angular/fire/remote-config';
import { data } from 'autoprefixer';
import { set } from 'lodash';
import { filter, firstValueFrom, from, map, Observable, take } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RemoteConfigService {
  remoteConfig = inject(RemoteConfig);
  configurations = signal<Map<string, any>>(new Map());
  initialized = signal<boolean>(false);

  // Firebase values
  getStringValue = getString; 
  getBooleanValue = getBoolean;
  remoteConfigFetchAndActivate = fetchAndActivate;

  /**
   * Sets the configurations from the remote firebase config.
   * @returns {void}
   */
  setConfigurations(): void {
    this.configurations.set(new Map<string, any>([
      ['ab_test_web_cta_sc_vs_callvet', this.getStringValue(this.remoteConfig, 'ab_test_web_cta_sc_vs_callvet')],
      ['markerstudy_symptoms_visible', this.getBooleanValue(this.remoteConfig, 'markerstudy_symptoms_visible')],
      ['ab_test_optimise_consultation_outcomes', this.getStringValue(this.remoteConfig, 'ab_test_optimise_consultation_outcomes')],
      ['symptom_checker_help_banner_excluded_algo_ids', this.getStringValue(this.remoteConfig, 'symptom_checker_help_banner_excluded_algo_ids')],
      ['chat_pms', this.getBooleanValue(this.remoteConfig, 'chat_pms')],
      ['tenant_ids_without_symptom_checker', this.getStringValue(this.remoteConfig, 'tenant_ids_without_symptom_checker')],
      ['chat_service_doc_id', this.getStringValue(this.remoteConfig, 'chat_service_doc_id')],
      ['chat_cta', this.getStringValue(this.remoteConfig, 'chat_cta')],
      ['chat_cta_afi', this.getStringValue(this.remoteConfig, 'chat_cta_afi')],
      ['llm_chat_enabled', this.getBooleanValue(this.remoteConfig, 'llm_chat_enabled')],
    ]));
  }

  $configurations = toObservable(this.configurations).pipe(
    filter((data) => data?.size > 0),
    take(1)
  );

  /**
   * Fetches and activates the remote firebase config.
   * @returns {Observable<boolean>} 
   * Where true is returned when the remote config is fetched
   * and false is return when the cache is used.
   */
  fetchAndActivate():Observable<boolean> {
    set(this.remoteConfig, 'settings.minimumFetchIntervalMillis', 0);
    return from(this.remoteConfigFetchAndActivate(this.remoteConfig)).pipe(
      map(result => {
        this.setConfigurations();
        this.initialized.set(true);
        return result;
      })
    );
  }
}
