<div id="chat-window-container"
    class="fixed inset-0 sm:inset-auto sm:bottom-2 sm:right-8 sm:left-auto w-full h-full sm:max-w-[392px] sm:h-[600px] bg-white rounded-none sm:rounded-[10px] shadow-[0px_6px_10px_0px_rgba(0,0,0,0.08)] shadow-[0px_-1px_10px_0px_rgba(0,0,0,0.08)] overflow-hidden">
    @switch(chatStore.chatWidgetView()) {
    @case(chatViewEnum.chatList) {
    <stream-channel-list>
        <div channel-list-top>
            <div class="h-[72px] w-full bg-[#212e4a]">
                <div class="flex items-center justify-between h-full px-6">
                    <button
                        class="w-8 h-8 rounded-full bg-white disabled:bg-[#d1d1d1] disabled:cursor-not-allowed flex items-center justify-center"
                        [disabled]="createChatDisabled()" (click)="createNewChat()">
                        <lucide-angular [color]="createChatDisabled() ? 'white' : '#212e4a'" name="square-pen"
                            size="16" />
                    </button>
                    <span class="text-center text-white text-lg font-medium leading-[27px] tracking-tight">Chat with a
                        vet</span>
                    <lucide-angular name="minus" color="white" class="cursor-pointer"
                        (click)="chatStore.toggleChatWidget()"></lucide-angular>
                </div>
            </div>
        </div>
    </stream-channel-list>
    }
    @case (chatViewEnum.chat) {
    <stream-channel>
        <app-chat-header />
        <stream-message-list data-automationid="stream-message-list"></stream-message-list>
        <stream-notification-list></stream-notification-list>
        <app-ai-status/>
        <stream-message-input [displaySendButton]="chatStore.ai_status() === ''" data-automationid="stream-message-input"></stream-message-input>
        <stream-thread name="thread">
            <stream-message-list mode="thread"></stream-message-list>
            <stream-message-input mode="thread"></stream-message-input>
        </stream-thread>
    </stream-channel>
    }
    }
</div>

<!-- <ng-template #customAttachmentPreviews let-attachments="attachments">
    GOT HERE
    
    @for(attachment of attachments; track $index){
    @if(attachment.type === 'call'){
    <button class="joii-btn-primary">{{attachment.state}}</button>
    }
    }
</ng-template> -->