import { Routes } from "@angular/router";
import { NoPetsGuard } from "../booking/guards/pet/nopets.guard";
import { LoginGuard } from "src/shared/guards/login.guard";

export const AccountRoutes: Routes = [
    { 
        path: 'account', loadComponent: () => import('./pages/account/account.component').then(m => m.AccountComponent), canActivate: [LoginGuard],
        children: [
            { path: '', redirectTo: 'user', pathMatch: 'full' },
            { path: 'user', loadComponent: () => import('./pages/userupdate/userupdate.component').then(m => m.UserUpdateComponent) },
            { path: 'pets', loadComponent: () => import('./pages/pets/pets.component').then(m => m.PetsComponent) },
            { path: 'pets/new', loadComponent: () => import('./pages/addpet/addpet.component').then(m => m.AddPetComponent) },
            { path: 'pets/:id', loadComponent: () => import('./pages/petdetails/petdetails.component').then(m => m.PetDetailsComponent) },
            { path: 'pets/:id/edit', loadComponent: () => import('./pages/updatepet/updatepet.component').then(m => m.UpdatePetComponent) },
            { path: 'appointments', loadComponent: () => import('./pages/appointments/appointments.component').then(m => m.AppointmentsComponent), canActivate: [NoPetsGuard], data: { endpoint: 'book'} },
            { path: 'appointments/all', loadComponent: () => import('./pages/appointmentsall/appointmentsall.component').then(m => m.AppointmentsAllComponent) },
            { path: 'resetpassword', loadComponent: () => import('./pages/resetpassword/resetpassword.component').then(m => m.ResetPasswordComponent) },
            { path: 'resetpassword/:userId', loadComponent: () => import('./pages/resetpassword-confirm-new/resetpassword-confirm-new.component').then(m => m.ResetpasswordConfirmNewComponent) },
            { path: 'logout', loadComponent: () => import('./pages/logoutpage/logoutpage.component').then(m => m.UserLogoutComponent) },
            { path: 'deleteaccount', loadComponent: () => import('./pages/deleteaccount/deleteaccount.component').then(m => m.DeleteAccountComponent) },
            { path: 'symptomchecker', loadComponent: () => import('../../symptomchecker/pages/sc-user-history/sc-user-history.component').then(m => m.ScUserHistoryComponent) },
        ]
     },
]