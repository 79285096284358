import { inject, Injectable } from '@angular/core';
import { bookingexternal } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
  providedIn: 'root'
})
export class ChatDataService {
  clientService = inject(ClientService);

  GetToken() {
    return this.clientService.requestWithClient(client => client.bookingexternal.GetToken('me'));
  }

  ListMyChats() {
    return this.clientService.requestWithClient(client => client.bookingexternal.ListChats('me'));
  }

  CreateChat(params: bookingexternal.CreateChatRequest) {
    return this.clientService.requestWithClient(client => client.bookingexternal.CreateChat('me', params));
  }

  GetChat(chatId: string) {
    return this.clientService.requestWithClient(client => client.bookingexternal.GetChat('me', chatId));
  }
}
