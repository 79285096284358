import { effect, inject, untracked } from "@angular/core";
import { tapResponse } from "@ngrx/operators";
import { patchState, signalStore, withHooks, withMethods, withState } from "@ngrx/signals";
import { rxMethod } from "@ngrx/signals/rxjs-interop";
import { first } from "lodash";
import { exhaustMap, filter, pipe, of } from "rxjs";
import { AvailabilityDataService } from "src/app/services/availability.data.service/availability.data.service";
import { BookingDataService } from "src/app/services/booking.data.service/booking.data.service";
import { bookingexternal } from "src/shared/services/client/client";
import { withUser } from "../features/user/user.feature";
import { withAuthStateReset } from "../features/auth-reset/auth-reset.feature";
import { ClientErrorMessage } from "src/shared/services/client/clienterror.interface";

type OnDemandState = {
    slots: bookingexternal.ListAvailabilitySlotItem[],
    services: bookingexternal.GetServiceWithPriceResponse[],
    refreshInterval: number,
    ref: any;
    initalLoad: boolean;
    loadingServices: boolean;
    loadingAvailability: boolean;
    errorServices: ClientErrorMessage | undefined;
    errorAvailability: ClientErrorMessage | undefined;
}

const initialState: OnDemandState = {
    slots: [],
    services: [],
    refreshInterval: 15000,
    ref: null,
    initalLoad: true,
    loadingServices: false,
    loadingAvailability: false,
    errorServices: undefined,
    errorAvailability: undefined,
}
/**
 * OnDemandStore is a signal store that provides the state and methods for the on-demand booking feature.
 */
export const OnDemandStore = signalStore(
    withState(initialState),
    withUser(), // Provides the user state to the store.
    withAuthStateReset<OnDemandState>(initialState), // Resets the store when the user logs out.
    withMethods((store, availabilityService = inject(AvailabilityDataService), bookingService = inject(BookingDataService)) => {
        /**
         * Sets the possible video services in the store.
         */
        const setServices = rxMethod<void>(
            pipe(
                exhaustMap(() => {
                    patchState(store, { loadingServices: true, errorServices: undefined });
                    return bookingService.ListServices().pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { services: response.services, errorServices: undefined });
                            },
                            error: (error: any) => {
                                patchState(store, { services: [], errorServices: error })
                                console.error(error)
                            },
                            finalize: () => {
                                patchState(store, { loadingServices: false });
                            }
                        })
                    )
                })
            )
        )
        /**
         * Refreshes the availability slots in the store.
         * @param services The services to get availability for.
         */
        const refreshAvailability = rxMethod<bookingexternal.GetServiceWithPriceResponse[]>(
            pipe(
                filter((services) => services && services?.length > 0),
                exhaustMap((services: bookingexternal.GetServiceWithPriceResponse[]) => {
                    const vetService = services.find((x) => x.name?.toLowerCase().includes('vet'));
                    const nurseService = services.find((x) => x.name?.toLowerCase().includes('nurse'));
                    const ServiceDocID = vetService?.doc_id || nurseService?.doc_id;
                    if (!ServiceDocID) {
                        patchState(store, { slots: [], initalLoad: false, errorAvailability: undefined });
                        return of(null);
                    }
                    patchState(store, { loadingAvailability: true, errorAvailability: undefined });
                    return availabilityService.ListOnDemand({ ServiceDocID, Timezone: bookingService.getUserTimezone()}).pipe(
                        tapResponse({
                            next: (response) => {
                                patchState(store, { slots: response.slots, initalLoad: false, errorAvailability: undefined });
                            },
                            error: (error: any) => {
                                patchState(store, { slots: [], errorAvailability: error })
                                console.error(error)
                            },
                            finalize: () => {
                                patchState(store, { loadingAvailability: false });
                            }
                        })
                    )
                })
            )
        );
        return {refreshAvailability, setServices}
    }),
    withHooks({
        onInit(store) {
            store.refreshAvailability(store.services); // Refresh availability when services change.
            // Sets services when the user logs in.
            effect(() => {
                const user = store.user();
                untracked(() => {
                    if (user !== null) {
                        patchState(store, { initalLoad: true });
                        store.setServices();
                    }
                });
            }, {allowSignalWrites: true});
        }
    })
)

