import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { bookingexternal, bookinginternal, consultationexternal, entities } from 'src/shared/services/client/client';
import { ClientService } from 'src/shared/services/client/client.service';

@Injectable({
    providedIn: 'root'
})

export class BookingDataService {

    constructor(private client: ClientService) { }

    CreateAppointment(params: bookingexternal.CreateAppointmentRequest): Observable<bookingexternal.CreateAppointmentResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.CreateAppointment("me", params));
    }

    UpdateAppointment(appointmentId: string, params: bookinginternal.UpdateAppointmentSlotRequest): Observable<void> {
        return this.client.requestWithClient(client => client.bookinginternal.UpdateAppointmentSlot(appointmentId, params));
    }

    CreateOndemandAppointment(params: bookingexternal.CreateOnDemandAppointmentRequest): Observable<bookingexternal.CreateAppointmentResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.CreateOnDemandAppointment("me", params));
    }

    CreateInternalAppointment(params: any): Observable<bookinginternal.CreateAppointmentResponse> {
        return this.client.requestWithClient(client => client.bookinginternal.CreateInternalAppointment(params));
    }


    GetAppointment(appointmentId: string): Observable<bookingexternal.GetAppointmentResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.GetAppointment("me", appointmentId));
    }

    UpdateAppointmentStatus(appointmentId: string, params: bookingexternal.UpdateAppointmentStatusRequest): Observable<void> {
        return this.client.requestWithClient(client => client.bookingexternal.UpdateAppointmentStatus("me", appointmentId, params));
    }

    getUserTimezone() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    ListMyAppointments(params: bookingexternal.ListAppointmentsRequest): Observable<bookingexternal.ListAppointmentsResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.ListAppointments("me", params));
    }

    ListServices(): Observable<bookingexternal.ListServicesResponse> {
        return this.client.requestWithClient(client => client.bookingexternal.ListServices());
    }

    ListInternalServices(params: bookinginternal.ListServicesRequest): Observable<bookinginternal.ListServicesResponse> {
        return this.client.requestWithClient(client => client.bookinginternal.ListServices(params));
    }

    CreateAppointmentPhoto(appointment_doc_id: string, params: bookingexternal.CreateAppointmentPhotoRequest): Observable<void> {
        return this.client.requestWithClient(client => client.bookingexternal.CreateAppointmentPhoto("me", appointment_doc_id, params));
    }

    PatchAppointmentNotes(user_doc_id: string, appointment_doc_id: string, params: bookingexternal.PatchAppointmentNotesRequest): Observable<void> {
        return this.client.requestWithClient(client => client.bookingexternal.PatchAppointmentNotes(user_doc_id, appointment_doc_id, params))
    }

    GetTreatmentPlan(appointment_doc_id: string): Observable<consultationexternal.ConsultationTreatmentPlanResponse> {
        return this.client.requestWithClient(client => client.consultationexternal.GetTreatmentPlan(appointment_doc_id));
    }

    ListTreatmentPlansForPet(user_doc_id: string, pet_doc_id: string, params: consultationexternal.ListTreatmentPlansRequest): Observable<entities.TreatmentPlanReadyList> {
        return this.client.requestWithClient(client => client.consultationexternal.ListTreatmentPlansForPet(user_doc_id, pet_doc_id, params));
    }

    GetUnreadTreatmentPlansCountForPet(user_doc_id: string, pet_doc_id: string) {
        return this.client.requestWithClient(client => client.consultationexternal.GetUnreadTreatmentPlanCountForPet(user_doc_id, pet_doc_id));
    } 

    GetTreatmentPlanByConsultation(consultation_doc_id: string) {
        return this.client.requestWithClient(client => client.consultationexternal.GetTreatmentPlanByConsultation(consultation_doc_id));
    }

    MarkTreatmentPlanAsReadByConsultation(consultation_doc_id: string): Observable<void> {
        return this.client.requestWithClient(client => client.consultationexternal.MarkTreatmentPlanAsReadByConsultation(consultation_doc_id));
    }
 
    MarkTreatmentPlanAsRead(appointment_doc_id: string): Observable<void> {
        return this.client.requestWithClient(client => client.consultationexternal.MarkTreatmentPlanAsRead(appointment_doc_id));
    }

    PatchAppointmentTypeformEventID(appointment_doc_id: string, params: bookingexternal.PatchAppointmentTypeformEventIDRequest): Observable<void> {
        return this.client.requestWithClient(client => client.bookingexternal.PatchAppointmentTypeformEventID("me", appointment_doc_id, params))
    }
}
