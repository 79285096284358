import { APP_INITIALIZER, ErrorHandler, importProvidersFrom, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HttpClient, provideHttpClient } from "@angular/common/http";
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LucideAngularModule } from 'lucide-angular';
import { ICONS, NGICONS } from 'src/icons';
import { BrazeInitializeFactory, BrazeService } from './services/braze.service/braze.service';
import { NgIconsModule } from '@ng-icons/core';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/shared/translate/custom.translate.loader';
import { provideRouter, RouterOutlet, withRouterConfig } from '@angular/router';
import { appRoutes } from './app-routing';
import { AngularSvgIconModule } from 'angular-svg-icon';
import * as Sentry from '@sentry/angular-ivy';
import { Auth, getAuth, provideAuth } from '@angular/fire/auth';
import { provideAnalytics, getAnalytics } from '@angular/fire/analytics';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideFirestore, getFirestore } from '@angular/fire/firestore';
import { provideStorage, getStorage } from '@angular/fire/storage';
import { environment } from 'src/environments/environment';
import { JoiiHeaderComponent } from 'src/shared/components/design/joii-header/joii-header.component';
import { JoiiFooterComponent } from 'src/shared/components/design/joii-footer/joii-footer.component';
import { provideRemoteConfig, getRemoteConfig } from '@angular/fire/remote-config';
import { ChatFloatingTriggerComponent } from "./joii-chat/components/chat-floating-trigger/chat-floating-trigger.component";

@NgModule({
  declarations: [
    AppComponent,
  ],
  providers: [
    provideHttpClient(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: BrazeInitializeFactory,
      multi: true,
      deps: [BrazeService]
    },
    // This ensures auth is ready to rock and roll before any component is created
    {
      provide: APP_INITIALIZER,
      useFactory: (auth: Auth) => () => new Promise((resolve) => {
        auth.onAuthStateChanged((user) => {
          // Firebase is ready, regardless of user being logged in or not
          resolve(true);
        });
      }),
      deps: [Auth],
      multi: true,
    },
    importProvidersFrom(TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },

    })),
    provideRouter(appRoutes, withRouterConfig({ paramsInheritanceStrategy: 'always' })),
  ],
  bootstrap: [AppComponent],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAnalytics(() => getAnalytics()),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideRemoteConfig(() => getRemoteConfig()),
    BrowserAnimationsModule,
    // Keep AppRoutingModule at bottom
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-center',
    }),
    LucideAngularModule.pick(ICONS),
    NgIconsModule.withIcons(NGICONS),
    AngularSvgIconModule.forRoot(),
    JoiiHeaderComponent,
    JoiiFooterComponent,
    RouterOutlet,
    ChatFloatingTriggerComponent
  ]
})
export class AppModule {
  constructor(private translate: TranslateService) {
    translate.setDefaultLang('en');
    const browserLang = translate.getBrowserLang();
    if (browserLang && browserLang.match(/en|de|fr/)) {
      translate.use(browserLang);
    } else {
      translate.use('en');
    }
    const savedLang = localStorage.getItem('lang');
    if (savedLang) {
      translate.use(savedLang);
    }
  }
}
